import { createSlice } from '@reduxjs/toolkit';

const initialState= {};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    updateMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const { updateMessage } = messageSlice.actions;
export default messageSlice.reducer;

export const sendMessage = ( message ) => (dispatch, getState) => {
  window.parent.postMessage(message, "*");
  dispatch(updateMessage(message ));
}

export const messageHandler = message => (dispatch, getState) => {
  const { frameId } = window;
  if (frameId === message.frameId) {
    console.info(`Component messageHandler received Post Message`, message);
    switch (message.event) {
      case "start": {
        dispatch(updateMessage(message));
        dispatch(sendMessage({ event: "playing", frameId: frameId }));
        if (window.duration) {
          setTimeout(() => {
            console.info(`Component messageHandler send Post Message`, {event: "end", frameId: frameId});
            dispatch(sendMessage({event: "end", frameId: frameId}));
          }, window.duration);
        }
        break;
      }
      case "stop": {
        dispatch(sendMessage({event: "end", frameId: frameId}));
        break;
      }
      default:
        break;
    }
  }
};

