
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';


import languageReducer from './languageSlice';
import messageReducer from './messageSlice';
import transactionReducer from './transactionSlice';


const persistConfig = {
  key: 'shell-lux',
  storage: storage,
  // whitelist: [''],
  // blacklist: [''],
};

const persistReducers = persistReducer(persistConfig, combineReducers({
  language: languageReducer,
  message: messageReducer,
  transaction: transactionReducer,

}));

const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  })),
});

export const persistor = persistStore(store);

export default store;
