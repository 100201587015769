const translations = {
  bg: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Сума",
      volume_unit_short: "L",
      volume_unit_long: "Литри",
      pump: "Помпа",
      welcome_message: "Добре дошли в Шел",
    },
    facility: {
      header: "На този сайт можете да намерите",
    },
    language: {
      header: "Изберете вашия език",
    },
    Pumpconfirmation1: {
      header: "Приятно и безопасно пътуване напред",
    },
    Pumpconfirmation3: {
      header: "Вие сте заредили на помпа номер",
    },
    weather: {
      daynames: {
        Monday: "Понеделник",
        Tuesday: "Вторник",
        Wednesday: "Сряда",
        Thursday: "Четвъртък",
        Friday: "Петък",
        Saturday: "Събота",
        Sunday: "Неделя",
      },
    },
  },
  cz: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Částka",
      volume_unit_short: "L",
      volume_unit_long: "Litry",
      pump: "Čerpadlo",
      welcome_message: "Vítejte ve Shell",
    },
    facility: {
      header: "Na tomto webu naleznete",
    },
    language: {
      header: "Vyberte si svůj jazyk",
    },
    Pumpconfirmation1: {
      header: "Přejeme vám příjemnou a bezpečnou cestu",
    },
    Pumpconfirmation3: {
      header: "Natankovali jste na čerpací stanici číslo",
    },
    weather: {
      daynames: {
        Monday: "Pondělí",
        Tuesday: "Úterý",
        Wednesday: "Středa",
        Thursday: "Čtvrtek",
        Friday: "Pátek",
        Saturday: "Sobota",
        Sunday: "Neděle",
      },
    },
  },
  de: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Betrag",
      volume_unit_short: "L",
      volume_unit_long: "Liter",
      pump: "Pumpe",
      welcome_message: "Willkommen bei Shell",
    },
    facility: {
      header: "Auf dieser Seite finden Sie",
    },
    language: {
      header: "Wählen Sie Ihre Sprache",
    },
    Pumpconfirmation1: {
      header: "Haben Sie eine schöne und sichere Reise",
    },
    Pumpconfirmation3: {
      header: "Sie haben an der Zapfsäule Nummer getankt",
    },
    weather: {
      daynames: {
        Monday: "Montag",
        Tuesday: "Dienstag",
        Wednesday: "Mittwoch",
        Thursday: "Donnerstag",
        Friday: "Freitag",
        Saturday: "Samstag",
        Sunday: "Sonntag",
      },
    },
  },
  en: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Amount",
      volume_unit_short: "L",
      volume_unit_long: "Liters",
      pump: "Pump",
      welcome_message: "Welcome to Shell",
    },
    facility: {
      header: "On this site you find",
    },
    language: {
      header: "Select your language",
    },
    Pumpconfirmation1: {
      header: "Have a nice and safe journey ahead",
    },
    Pumpconfirmation3: {
      header: "You have refuelled at pump number",
    },
    weather: {
      daynames: {
        Monday: "Monday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Sunday: "Sunday",
      },
    },
  },
  es: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Cantidad",
      volume_unit_short: "L",
      volume_unit_long: "Litros",
      pump: "Bomba",
      welcome_message: "Bienvenido a Shell",
    },
    facility: {
      header: "En este sitio encontrarás",
    },
    language: {
      header: "Selecciona tu idioma",
    },
    Pumpconfirmation1: {
      header: "Que tengas un buen y seguro viaje por delante.",
    },
    Pumpconfirmation3: {
      header: "Has repostado en la bomba número",
    },
    weather: {
      daynames: {
        Monday: "Lunes",
        Tuesday: "Martes",
        Wednesday: "miércoles",
        Thursday: "jueves",
        Friday: "viernes",
        Saturday: "sábado",
        Sunday: "domingo",
      },
    },
  },
  fr: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Montant",
      volume_unit_short: "L",
      volume_unit_long: "Litres",
      pump: "Pompe",
      welcome_message: "Bienvenue chez Shell",
    },
    facility: {
      header: "Sur ce site, vous trouverez",
    },
    language: {
      header: "Sélectionnez votre langue",
    },
    Pumpconfirmation1: {
      header: "Bon voyage et soyez prudent",
    },
    Pumpconfirmation3: {
      header: "Vous avez fait le plein à la pompe numéro",
    },
    weather: {
      daynames: {
        Monday: "Lundi",
        Tuesday: "Mardi",
        Wednesday: "Mercredi",
        Thursday: "Jeudi",
        Friday: "Vendredi",
        Saturday: "Samedi",
        Sunday: "Dimanche",
      },
    },
  },
  hu: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Összeg",
      volume_unit_short: "L",
      volume_unit_long: "Liter",
      pump: "Szivattyú",
      welcome_message: "Üdvözöljük a Shellnél",
    },
    facility: {
      header: "Ezen az oldalon található",
    },
    language: {
      header: "Válassza ki a nyelvét",
    },
    Pumpconfirmation1: {
      header: "Kellemes és biztonságos utat kívánunk",
    },
    Pumpconfirmation3: {
      header: "Az Ön tankolóállomásánál tankolt",
    },
    weather: {
      daynames: {
        Monday: "Hétfő",
        Tuesday: "Kedd",
        Wednesday: "Szerda",
        Thursday: "Csütörtök",
        Friday: "Péntek",
        Saturday: "Szombat",
        Sunday: "Vasárnap",
      },
    },
  },
  it: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Importo",
      volume_unit_short: "L",
      volume_unit_long: "Litri",
      pump: "Pompa",
      welcome_message: "Benvenuti in Shell",
    },
    facility: {
      header: "In questo sito trovi",
    },
    language: {
      header: "Seleziona la tua lingua",
    },
    Pumpconfirmation1: {
      header: "Buon viaggio e guidare con prudenza",
    },
    Pumpconfirmation3: {
      header: "Hai rifornito alla pompa numero",
    },
    weather: {
      daynames: {
        Monday: "Lunedì",
        Tuesday: "Martedì",
        Wednesday: "Mercoledì",
        Thursday: "Giovedì",
        Friday: "Venerdì",
        Saturday: "Sabato",
        Sunday: "Domenica",
      },
    },
  },
  nl: {
    date: {
      long: "d MMMM yyyy",
    },
    transaction: {
      amount: "Bedrag",
      volume_unit_short: "L",
      volume_unit_long: "Liters",
      pump: "Pomp",
      welcome_message: "Welkom bij Shell",
    },
    facility: {
      header: "Op dit station vindt u",
    },
    language: {
      header: "Selecteer uw taal",
    },
    Pumpconfirmation1: {
      header: "Fijne en veilige reis",
    },
    Pumpconfirmation3: {
      header: "U heeft getankt bij pompnummer",
    },
    weather: {
      daynames: {
        Monday: "Maandag",
        Tuesday: "Dinsdag",
        Wednesday: "Woensdag",
        Thursday: "DonderDag",
        Friday: "Vrijdag",
        Saturday: "Zaterdag",
        Sunday: "Zondag",
      },
    },
  },
  pl: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Kwota",
      volume_unit_short: "L",
      volume_unit_long: "Litry",
      pump: "Pompa",
      welcome_message: "Witaj w Shell",
    },
    facility: {
      header: "Na tej stronie znajdziesz",
    },
    language: {
      header: "Wybierz swój język",
    },
    Pumpconfirmation1: {
      header: "Miłej i bezpiecznej podróży",
    },
    Pumpconfirmation3: {
      header: "Zatankowałeś na numerze pompy",
    },
    weather: {
      daynames: {
        Monday: "Poniedziałek",
        Tuesday: "Wtorek",
        Wednesday: "Środa",
        Thursday: "Czwartek",
        Friday: "Piątek",
        Saturday: "Sobota",
        Sunday: "Niedziela",
      },
    },
  },
  ro: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Sumă",
      volume_unit_short: "L",
      volume_unit_long: "Litri",
      pump: "Pompă",
      welcome_message: "Bine ai venit la Shell",
    },
    facility: {
      header: "Pe acest site găsești",
    },
    language: {
      header: "Selectează limba ta",
    },
    Pumpconfirmation1: {
      header: "Să aveți o călătorie plăcută și sigură înainte",
    },
    Pumpconfirmation3: {
      header: "Ați alimentat la pompa numărul",
    },
    weather: {
      daynames: {
        Monday: "Luni",
        Tuesday: "Marți",
        Wednesday: "Miercuri",
        Thursday: "Joi",
        Friday: "Vineri",
        Saturday: "Sâmbătă",
        Sunday: "Duminică",
      },
    },
  },
  sk: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Množstvo",
      volume_unit_short: "L",
      volume_unit_long: "Litry",
      pump: "Čerpadlo",
      welcome_message: "Vitajte v Shell",
    },
    facility: {
      header: "Na tomto mieste nájdete",
    },
    language: {
      header: "Vyberte si jazyk",
    },
    Pumpconfirmation1: {
      header: "Prajeme vám príjemnú a bezpečnú cestu ďalej",
    },
    Pumpconfirmation3: {
      header: "Zaparkovali ste na čerpacom mieste číslo",
    },
    weather: {
      daynames: {
        Monday: "Pondelok",
        Tuesday: "Utorok",
        Wednesday: "Streda",
        Thursday: "Štvrtok",
        Friday: "Piatok",
        Saturday: "Sobota",
        Sunday: "Nedeľa",
      },
    },
  },
  sl: {
    date: {
      long: "MMMM do, yyyy",
    },
    transaction: {
      amount: "Znesek",
      volume_unit_short: "L",
      volume_unit_long: "Litri",
      pump: "Črpalka",
      welcome_message: "Dobrodošli v Shell",
    },
    facility: {
      header: "Na tej strani najdete",
    },
    language: {
      header: "Izberite svoj jezik",
    },
    Pumpconfirmation1: {
      header: "Imejte lepo in varno pot",
    },
    Pumpconfirmation3: {
      header: "Ste natočili pri črpalki številka",
    },
    weather: {
      daynames: {
        Monday: "Ponedeljek",
        Tuesday: "Torek",
        Wednesday: "Sreda",
        Thursday: "Četrtek",
        Friday: "Petek",
        Saturday: "Sobota",
        Sunday: "Nedelja",
      },
    },
  },
};

export default translations;
