import React, { useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./store";
import Routes from "./Routes";
import { setTranslations, setLocale } from "react-i18nify";
import translations from "../i18n/translations";

import "./App.css";
import { setActiveLanguage } from './languageSlice';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const handleStorageChange = (event) => {
      const { storageArea } = event;
      if (storageArea.activeLanguage) {
        setTranslations(translations);
        setLocale(storageArea.activeLanguage);
        dispatch(setActiveLanguage(storageArea.activeLanguage));
      }
    };
    window.addEventListener("storage", handleStorageChange);
  }, [dispatch]);

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={<div>Loading</div>} persistor={persistor}>
          <Routes />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
