import { createSlice } from '@reduxjs/toolkit';

const GRANTED_LITERS = 500;

const initialState= {
    pumpnumbers:'',
    maxLitersGranted: false,
    totalLiters:0
}

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    updatePumpNumber: (state, action) => {
       state.pumpnumbers = action.payload.toString();
    },
    setLiters: (state, action) => {
      if (action.payload.index === 0){
        state.totalLiters += action.payload.liters
      }
      if (action.payload.index === 1){
        state.totalLiters += action.payload.liters
      }
      if (action.payload.index === 2){
        state.totalLiters += action.payload.liters
      }
      state.maxLitersGranted = state.totalLiters < GRANTED_LITERS ? false : true;
    },
    restoreMaxLiters: (state, action) => {
      console.log (`restore Max Liters to ${action.payload.totalLiters} and Granted is false`);
      state.totalLiters = action.payload.totalLiters;
      state.maxLitersGranted = false;
    }
    
    
  },
});

export const { setLiters, updatePumpNumber, restoreMaxLiters } = transactionSlice.actions;
export default transactionSlice.reducer;