import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  default: {
    language: "en",
    languageArray: [
      "de",
      "en",
      "es",
      "fr",
      "hu",
      "it",
      "nl",
      "bg",
      "sl",
      "ro",
      "cz",
      "sk",
      "pl",
    ],
  },
  activeLanguage: "en",
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    updateDefaultLanguage: (state, { payload }) => {
      state.default.language = payload;
    },
    updateDefaultLanguageArray: (state, { payload }) => {
      state.default.languageArray = payload;
    },
    setActiveLanguage: (state, { payload }) => {
      state.activeLanguage = payload
    },
  },
});

export const {
  updateDefaultLanguage,
  updateDefaultLanguageArray,
  setActiveLanguage,
} = languageSlice.actions;

export default languageSlice.reducer;
