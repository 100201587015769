import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import "./font/fonts.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store, { persistor } from './app/store';

const params = new URLSearchParams(document.location.search);
window.playerId = params.get("playerId");
window.frameId = params.get("iframeId");
window.duration = params.get("duration");

document.addEventListener("DOMContentLoaded", function (event) {
  window.parent.postMessage({ event: "loaded", frameId: window.frameId }, "*");
});

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={<div>Loading</div>} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    console.info(
      "Received a service worker update, unregister old service worker and restart browser"
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: (registration) => {
    console.log("New service worker loaded");
  },
});
